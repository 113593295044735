<template>
  <div id="detail-page">
    <v-card-title class="text-capitalize justify-center justify-lg-start primary--text pa-0 font-weight-bold">my details</v-card-title>
    <v-row>
        <v-col cols="12" class="col-lg-3">
            <v-row class="pa-0 ma-0 mb-5" align="center" justify="space-between">
                <v-btn type="button" color="secondary" large class="w-full font-weight-bold rounded-lg">Personal</v-btn>
            </v-row>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" class="col-lg-6 pa-0">
            <v-row class="ma-0 pa-0" justify="space-between">
                <v-col cols="12" class="col-lg-6">
                    <Input label="First Name*" :labelCls="{'error--text': valid.model.first_name.$dirty && !valid.model.first_name.required}">
                        <template v-slot:input>
                            <v-text-field ref="first_name" class="rounded-lg text-capitalize" color="primary"  v-model="model.first_name"
                            :error-messages="$helpers.errorMsg('name', valid.model.first_name, 'First Name')" 
                            hide-details="auto" placeholder="First Name" label="" outlined required /> 
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-6">
                    <Input label="Last Name*" :labelCls="{'error--text': valid.model.last_name.$dirty && !valid.model.last_name.required}">
                        <template v-slot:input>
                            <v-text-field ref="last_name" class="rounded-lg text-capitalize" color="primary"  v-model="model.last_name"
                            :error-messages="$helpers.errorMsg('name', valid.model.last_name, 'First Name')" 
                            hide-details="auto" placeholder="Last Name" label="" outlined required /> 
                        </template>
                    </Input>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="col-lg-6 pa-0">            
            <Input classProps="pa-3" label="Phone Number*" :labelCls="{'error--text': valid.model.phone.$dirty && !valid.model.phone.required}">
                <template v-slot:input>
                    <v-text-field type="number" ref="phone" class="rounded-lg text-capitalize" color="primary"  v-model="model.phone"
                    :error-messages="$helpers.errorMsg('name', valid.model.phone, 'Phone Number')"
                    hide-details="auto" placeholder="Phone Number" label="" outlined required /> 
                </template>
            </Input>
        </v-col>
        <v-col cols="12" class="col-lg-6 pa-0">
            <Input classProps="pa-3" label="address*" :labelCls="{'error--text': valid.model.address1.$dirty && !valid.model.address1.required}">
                <template v-slot:input>
                    <v-text-field ref="address1" id="search-address" class="rounded-lg text-capitalize" color="primary"  v-model="model.address1"
                    :error-messages="$helpers.errorMsg('name', valid.model.address1, 'Address')" 
                    hide-details="auto" placeholder="Address" label="" outlined required /> 
                </template>
            </Input>
            <Input classProps="pa-3" label="Town/Suburb*" :labelCls="{'error--text': valid.model.suburb.$dirty && !valid.model.suburb.required}">
                <template v-slot:input>
                    <v-text-field ref="suburb" class="rounded-lg text-capitalize" color="primary"  v-model="model.suburb"
                    :error-messages="$helpers.errorMsg('name', valid.model.suburb, 'Phone Number')" 
                    hide-details="auto" placeholder="Town/Suburb" label="" outlined required /> 
                </template>
            </Input>
            <v-row class="w-full ma-0" align="center" justify="space-between">
                <v-col cols="12" class="col-lg-4">
                    <Input label="Postcode*" :labelCls="{'error--text': valid.model.postcode.$dirty && !valid.model.postcode.required}">
                        <template v-slot:input>
                            <v-text-field ref="postcode" class="rounded-lg text-capitalize" color="primary"  v-model="model.postcode"
                            :error-messages="$helpers.errorMsg('name', valid.model.postcode, 'Postcode')"
                            hide-details="auto" placeholder="Postcode" label="" outlined required /> 
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                    <Input label="State/Territory*" :labelCls="{'error--text': valid.model.state.$dirty && !valid.model.state.required}">
                        <template v-slot:input>
                            <v-text-field ref="state" class="rounded-lg text-capitalize" color="primary"  v-model="model.state"
                            :error-messages="$helpers.errorMsg('name', valid.model.state, 'State/Territory')"
                            hide-details="auto" placeholder="State/Territory" label="" outlined required /> 
                        </template>
                    </Input>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                    <Input label="Country*" :labelCls="{'error--text': valid.model.country.$dirty && !valid.model.country.required}">
                        <template v-slot:input>
                            <v-text-field ref="country" class="rounded-lg text-capitalize" color="primary"  v-model="model.country"
                            :error-messages="$helpers.errorMsg('name', valid.model.country, 'Country')"
                            hide-details="auto" placeholder="Country" label="" outlined required /> 
                        </template>
                    </Input>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" class="col-lg-6 pa-0">
            <Input classProps="pa-3" label="Email*" :labelCls="{'error--text': valid.model.email.$dirty && !valid.model.email.required}">
                <template v-slot:input>
                    <v-text-field ref="email" class="rounded-lg text-capitalize" color="primary"  v-model="model.email"
                    :error-messages="$helpers.errorMsg('email', valid.model.email, 'Email')"
                    hide-details="auto" placeholder="Email" label="" outlined required /> 
                </template>
            </Input>
            <div class="pa-3">
                <v-checkbox hide-details="auto" class="subscribe-label" color="secondary" v-model="model.subscribe_news"
                    on-icon="mdi-checkbox-intermediate" off-icon="mdi-checkbox-blank-outline"
                    label="Subscribe to TheLight News" :false-value="0" :true-value="1"
                ></v-checkbox>
            </div>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';

import { Input, Dropdown } from '@/components';

export default {
    name: 'Detail',
    props: ['valid', 'submitForm'],
    emits: ["updateRefs"],
    components: {
        Input,
        Dropdown
    },
    data() {
        return {
            stateLists: ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia'],
            countryLists: ['Australia'],
        };
    },
    computed: {
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("updateModel", newValue);
            },
        },
    },
    created() {
        this.formValid = _.debounce(this.formValid, 200);
    },
    mounted() {
        const searchFieldSelector = $('#search-address'),
            self = this;

        // Address Capture options and initialization
        const address = new ContactDataServices.address({
            layout: 'IntuitiveDefault',
            token: window.VUE_APP_EXPERIAN_API_KEY,
            elements: {
                input: searchFieldSelector[0],
                // Hard-coded country if only single country supported.
                countryList: {
                    value: 'AUS'
                },
            },
            placeholderText: 'Searching an address',
            applyFocus: false,
        });
        address.events.on("post-formatting-search", function(item){
            const address = item.address;
            self.model.address1 = `${address[0].addressLine1} ${address[1].addressLine2} ${address[2].addressLine3}`;
            self.model.suburb = address[3].locality;
            self.model.state = address[4].province;
            self.model.postcode = address[5].postalCode;
            self.model.country = address[6].country;
        });
        this.$emit('updateRefs', this.$refs)
    },
    methods: {
        formValid() {
            if(this.submitForm) {
                this.valid.model.business_name.$touch();
            }
        }
    }
};
</script>
